import React from 'react'
import queryString from 'query-string'
import toastr from 'toastr'
import axios from 'axios'
import './App.css'

const SubscriptionModes = {
  Unsubscribe: 'unsubscribe',
  ManageSubscription: 'manage'
}

axios.defaults.headers.common['Authorization'] = `Basic ${btoa(`${process.env.REACT_APP_SUBSCRIPTION_AUTH_USERNAME}:${process.env.REACT_APP_SUBSCRIPTION_AUTH_PASSWORD}`)}`

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SUBSCRIPTION_API_ENDPOINT
})

class App extends React.Component {
  constructor () {
    super ()

    this.state = {
      mode: '',
      unsubscribed: false,
      dataFetched: false
    }
  }

  async componentDidMount() {
    try {
      const parsed = queryString.parse(this.props.location.search)

      const { mode, hash } = parsed

      const response = await axiosInstance.get(`/status?hash=${hash}`)

      const { subscribed } = response.data

      this.setState({
        unsubscribed: !subscribed,
        dataFetched: true,
        mode
      })
    } catch (error) {
      this.displayToast('error', 'Fetching subscription status failed', null, error)
    }
  } 

  unsubscribeUser = async () => {
    try {
      const parsed = queryString.parse(this.props.location.search)
      const { hash } = parsed

      await axiosInstance.post('/unsubscribe', { hash })

      this.setState({
        unsubscribed: true
      })
      this.displayToast('success', 'Unsubscribed', 'You have been unsubscribed')
    } catch (error) {
      this.displayToast('error', 'Unsubscribing user has failed', null, error)
    }
  }

  /**
   * 
   * @param {string} type - Type of toast to display.
   * @param {string} title - Title for toast.
   * @param {string} text - Text for toast.
   * @param {object} error - Error object.
   */
  displayToast = (type, title, text, error = null) => {
    if (error) {
      text = (error.response) ? error.response.data.message : error.message
    }

    // Display toast.
    toastr[type].call(null, text || 'An error has occurred.', title)
  }

  subscribeUser = async () => {
    try {
      const parsed = queryString.parse(this.props.location.search)
      const { hash } = parsed

      await axiosInstance.post('/subscribe', { hash })

      this.setState({
        unsubscribed: false
      })
      this.displayToast('success', 'Subscribed', 'You have been subscribed')
    } catch (error) {
      this.displayToast('error', 'Subscribing user has failed', null, error)
    }
  }
  
  render () {
    const { unsubscribed, dataFetched, mode } = this.state
  
    return (
      <section className='subscription-section'>
        <div className='subscription-cover' style={{ backgroundImage: "url('/background.png')", backgroundPosition: 'right top', backgroundRepeat: 'no-repeat' }}>
          <img src='/lplogo.svg' alt='Learning Pool' className='logo' />
        </div>

        
        <div className='subscription-container'>
          <div className='subscription-panel'>
            {dataFetched && 
              <>
                <img src='/lplogo.svg' alt='Learning Pool' className='lp-logo' />

                {mode === SubscriptionModes.Unsubscribe &&
                  <>
                    {!unsubscribed && 
                      <>
                        <div className="title">
                          <h4>To stop receiving automatic emails click the button below.</h4>
                        </div>
                        <div>
                          <button className='unsubscribe-button' onClick={this.unsubscribeUser}>Unsubscribe</button>
                        </div>
                      </>
                    }
                    {unsubscribed && 
                      <>
                        <div className="title">
                          <h2>Unsubscribed</h2>
                        </div>
                        <div> You are unsubscribed from the campaign and will no longer receive updates. </div><br></br>
                        <div>If you would like to resubscribe you can use the <b>Manage Subscription</b> link from a campaign email.</div>
                      </>
                    }
                  </>
                }

                {mode === SubscriptionModes.ManageSubscription &&
                  <>
                    {unsubscribed && 
                      <>
                        <div className="title">
                          <h4>You are currently unsubscribed from the campaign, if you would like to start receiving updates you can subscribe using the button below</h4>
                        </div>
                        <div>
                          <button onClick={this.subscribeUser}>Subscribe</button>
                        </div>
                      </>
                    }
                    {!unsubscribed && 
                      <>
                        <div className="title">
                          <h2>Subscribed</h2>
                        </div>
                        You have been subscribed to the campaign and will start receiving updates.
                      </>
                    }
                  </>
                }
              </>
            }
          </div>

        </div>
      </section>
    )
  }
}

export default App
